// js call native
export enum BridgeCallHandlers {
  GET_CLIENT_INFO_SYNC = "getClientInfoSync",
  GET_CLIENT_INFO_ASYNC = "getClientInfoAsync",
  AGREE_MASTER_ACCOUNT_AGREEMENTS = "agreeMasterAccountAgreements",
  ON_NFT_AVATAR_SELECT = "onNFTAvatarSelect",
  ON_NFT_COVER_SELECT = "onNFTCoverSelect",
  GET_SELECTD_NFT_COVER = "getSelectedNFTCover",
  GET_SELECTED_NFT_AVATAR = "getSelectedNFTAvatar",
  ON_CLOSE_WEB_PAGE = "onCloseWebPage",
  ON_SHARE_BUTTON_CLICK = "onShareButtonClick",
  ON_RAPYD_PAYMENT_PARAMS = "onRapydPaymentParams",
  ON_RAPYD_PAID_SUCCESS = "onRapydPaidSuccess",
  ON_RAPYD_PAID_FAIL = "onRapydPaidFail",
  ADD_EVENT = "addEvent",
  ON_PAY_PASSWORD_VERIFY = "onPayPasswordVerify",
  ON_DEPOSIT_SELECTED_COUNTRY = "onDepositSelectedCountry",
  ON_SLIDE_TO_TOP = "onSlideToTop",
  ON_SURVEY_COMPLETE = "onSurveyComplete",
  ON_CUSTOMER_SERVICE_BUTTON_CLICK = "onCustomerServiceButtonClick",
  ON_OPEN_WECHAT_COMMUNITY = "onOpenWechatCommunity",
  ON_GET_STATUS_BAR_HEIGHT = "onGetStatusBarHeight",
  ON_COPY_TRADING_TASK_CANCEL = "onCopyTradingTaskCancel",
  ON_GET_RECAPTCHA_TOKEN = "onGetRecaptchaToken",
  ON_SCREENSHOT = "onScreenshot",
  GET_SYSTEM_PUSH_ENABLED = "getSystemPushEnabled",
  JUMP_TO_SYSTEM_PUSH = "jumpToSystemPush",
  ON_RECAPTCHA_TOKEN_GET = "onRecaptchaTokenGet",
  IS_APP_INSTALLED = "isAppInstalled",
}

// native call js
export enum BridgeRegisterHandlers {
  SET_SELECT_COVER = "setSelectCoverSyn",
  SET_SELECT_AVATAR = "setSelectAvatar",
}

export enum ClientTheme {
  light = 1,
  dark = 2,
}

export enum AvatarUIStatus {
  ON_STORE = "onStore",
  ON_MYSTERY = "onMystery",
}

/*
 * 分享模块标识
 * 保证四位
 */
export enum ModulesMappings {
  holiday2022 = "HD22",
  avatar2022 = "AR22",
  cover2022 = "CR22",
  ranking2022 = "RS22",
  homePage2022 = "HP22",
  rebate2023 = "RC23",
  depositAward2023 = "DA23",
  preEarnings2023 = "PE23",
  stockRebate2024 = "RB24",
}

/**
 * 统计参数
 */
export enum UtmCampaign {
  EARLY_BIRD = "early_bird",
  COMMISSION_REWARD = "commission_reward",
}

// 客户端分享类型
export enum BridgeClientShareTypes {
  TEXT = "text",
  IMAGE = "image",
}

// 客户端分享数据上报业务key
export enum BridgeClientShareReportKeys {
  PULLING_NEW = "Rockwheel_share",
  NTF_AVATAR_SHARE = "NFTAvatar_share",
  NTF_COVER_SHARE = "NFTCover_share",
  POSTER_SHARE = "poster_share",
}

// 客户端数据卖点
export enum log2ClientPageIds {
  DEPOSIT_METHOD = "deposit-method",
  DEPOSIT_FINISHED = "deposit-finish",
}

export enum log2ClientEventTypes {
  CLICK = "click",
  IN_PAGE_VIEW = "in_pageView",
  OUT_PAGE_VIEW = "out_pageView",
}

// rapyd支付埋点 业务id
export enum log2ClientRapydResourcesIds {
  VIEW = "view", // 进入页面
  COUNTRY = "country", // 选择国家
  COUNTRY_CONFIRM = "country_confirm", // 选择国家弹窗
  CARD = "card", // 信用卡
  E_WALLET = "ewallet", // 电子钱包
  ONLINE_BANKING = "onlinebanking", // 线上银行
  WIRE_TRANSFER = "wire_transfer", // 电汇
  SECURITY = "security", // 资金安全
  OK = "ok", // 成功页点击
}

// 证件类型
export enum IdentityTypes {
  ID_CARD = 1,
  PASSPORT = 2,
  ALIEN_ID_CARD = 3,
}

// OCR提交状态
export enum OCRStatus {
  UN_COMMIT = 0, // 未提交
  COMMIT = 1, // 已经提交
}
// 开户问题提交状态
export enum OpenAccountQuestionStatus {
  UN_COMMIT = 0,
  COMMIT = 1,
}
// 入金问题提交状态
export enum DepositQuestionStatus {
  UN_COMMIT = 0,
  COMMIT = 1,
}
// 地址证明提交状态
export enum AddressProofStatus {
  UN_COMMIT = 0,
  COMMIT = 1,
}

// bridge 完成问卷状态
export enum SurveyCompleteTypes {
  OPEN_ACCOUNT = 1, // 开户问卷提交成功
  DEPOSIT = 2, // 入金问卷提交成功
  ADDRESS_PROOF = 3, // 地址证明提交成功
}

export enum OpenAccountRejectInitialReviewReasonTypes {
  DEFAULT = 0,
  REASON_KEY_1 = 1, //'请上传正确有效的本人证件',
  REASON_KEY_2 = 2, //'照片不够清晰，请重新上传清晰的彩色照片',
  REASON_KEY_3 = 3, //'需要露出证件的四个角或者包含你照片的整张页面',
  REASON_KEY_4 = 4, //'证件已经过期，请上传在有效期内的证件',
  REASON_KEY_5 = 5, //'不接受扫描件、复印件照片，请重新上传清晰的彩色照片',
  REASON_KEY_6 = 6, //'请上传包含英文姓名的有效证件',
  REASON_KEY_7 = 7, //'您填写的信息需要和上传的证件一致',
  REASON_KEY_8 = 8, //'你上传的证件不符合要求，你可以在官网或app帮助中心查看你所在国家支持的证件'
  REASON_KEY_9 = 9, // '你提交的证件号码已在另一账号使用，如非本人操作请联系客服'
}

export enum DepositQuestionsAuditStatus {
  UNKNOWN = 0, // 未知
  SUBMIT_PENDING = 1, // 未上传
  SUBMITTED = 2, // 已上传
  AUDIT_PASS = 3, // 审核通过
  REJECT = 4, // 驳回
}

export enum MasterAccountOpenStatus {
  UNKNOWN = -1,
  PENDING_SUBMIT = 1, // "待提交"
  SYSTEM_AUDIT = 2, // "系统审核"),
  MANUAL_INITIAL_REVIEW = 3, // "人工初审"),
  MANUAL_SECONDARY_REVIEW = 4, //"人工复审"),
  APPROVED = 5, // "审核通过"),
  FINISHED = 6, // "开户完成",
  USER_CONFIRMATION = 11, // "用户确认开户");
  REJECTED = 21, // "拒绝开户"
}

export enum TrendLabel {
  UNKNOWN = 0,
  BULLISH_TODAY = 1, // 看涨
  BEARISH_TODAY = 2, // 看跌
}

/**
 * 客户端deepLink target 映射
 */
export enum ClientTargetMappings {
  stockDetail = 0, // 股票详情
  option_detail = 1, // 期权详情
  option_betterBuy = 2, // 好买详情
  userHomePage = 3, // 个人主页
  watchListDetail = 4, // 股单详情
  rockStarRankList = 5, // 牛人榜单
  betterBuyOmnibus = 6, // 好买精选
  stock_better_buy = 7, // 股票好买
  tradeGPTDetail = 8, // gpt 详情
  tradeGPTList = 9, // gpt 列表
  positionSmartGuard = 10, // 小熊保险
  watchListGroup = 11, // 股单聚合
}

export enum PortalModuleTypes {
  BARRAGE = "barrage", // 弹幕区
  OPTION_FEATURED = "optionFeatured", // 今日赢家精选
  WATCHLIST = "watchlist", // 股单区
  NEWS = "news", // 新闻
  PK = "pk", // 期权页pk
  BEAR_HOT = "bearHot", // 热门小熊
  BULL_HOT = "bullHot", // 热门小牛
  LEARNING_CENTER = "learningCenter", // 投教中心
}

export enum HasNonMainlandBankCardEnum {
  UNKNOWN = 0,
  HAS = 1,
  DOES_NOT_HAVE = 2,
}

export enum BetterBuyOptionProd {
  unknown = 0,
  bull = 1,
  bear = 2,
}

export enum recaptchaScene {
  bind_email_or_phone = "bindEmailOrPhone",
}

export enum PromotionType {
  PULL_NEW = 1,
  IQIYI,
  DEPOSIT,
  NEW_DEPOSIT,
}

export enum Bots {
  rockBot = "default",
  teslaBot = "TSLA",
  muskBot = "elon_musk",
}

export enum OrderListPageEnum {
  ALL = 0,
  US_STOCK = 1,
  BABY_BULL_OR_BEAR = 2,
  BETTER_BUY = 3,
  HK_STOCK = 4,
  FINANCIAL_ORDER = 5,
  STOCK_BUNCH_COPY = 6,
}
