import { useEffect, ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import ErrorBoundary from "@/components/ErrorBoundary";

import { SWRConfig } from "swr";

import { appWithTranslation } from "next-i18next";
import { BridgeCallHandlers } from "@/interfaces/enums";
import bridge from "@/utils/dsBridge";
import rfStore from "@/utils/rfStore";
import JSONbig from "json-bigint";

import TagManager from "react-gtm-module";

import rfLocalStore from "@/utils/rfLocalStore";
import Layout from "@/layout/Layout";
import supportWebP, { isSupport } from "@/utils/supportWebp";
import Head from "next/head";
import { isAlwaysTheme, settingThemeStyle } from "@/utils/util";
import { transferLanguage } from "@/utils/transferLanguage";
import { ptenginLogin } from "@/utils/logo2Ptengin";
import { ClientTheme } from "@/interfaces/enums";
import settingTheme from "@/utils/settingTheme";
import useWindowSize from "@/hooks/useWindowSize";

import "../styles/globals.scss";
import "../styles/tailwindGlobal.css";
import "../styles/preflight.css";
import "../styles/ql-editor.scss";

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

// Init client Info from mobile
const clientInfo = bridge?.call(BridgeCallHandlers.GET_CLIENT_INFO_SYNC);
clientInfo && rfStore.setRFState(JSONbig.parse(clientInfo));

// Init client Info from LocalStorage
const initWebJWT = () => {
	if (!rfLocalStore.getToken() || !rfLocalStore.getUserId()) {
		return;
	}
	const clientInfo = rfStore.getRFState();
	clientInfo &&
		rfStore.setRFState({
			...clientInfo,
			token: rfLocalStore.getToken(),
			userId: rfLocalStore.getUserId(),
			region: rfLocalStore.getRegion(),
		});
};
initWebJWT();

// setting theme
const initTheme = () => {
	if (typeof document === "undefined" || typeof window === "undefined") {
		return;
	}

	const path = window.location.href;
	const _isAlwaysTheme = isAlwaysTheme(path);

	let app_theme = "";
	const _rfState = rfStore.getRFState();
	if (_rfState && _rfState.nightModel) {
		app_theme = _rfState.nightModel === ClientTheme.dark ? "dark" : "light";
	}

	let theme = "";
	theme = settingTheme();

	if (app_theme) {
		theme = app_theme;
	}

	if (_isAlwaysTheme) {
		theme = _isAlwaysTheme;
	}

	if (process.env.NODE_ENV === "development") {
		// theme = "dark";
		theme = "light";
	}

	/* Save global */
	const rfState = rfStore.getRFState();
	if (rfState) {
		rfStore.setRFState({
			...rfState,
			theme: theme,
		});
	}
	settingThemeStyle(theme);
};
initTheme();

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
	const { height } = useWindowSize();

	initTheme();
	// 设置请求多语言
	const rfState = rfStore.getRFState();
	if (rfState && router.locale) {
		rfStore.setRFState({
			region: "CN",
			...rfState,
			language: transferLanguage(router.locale),
		});
	}

	useEffect(() => {
		TagManager.initialize({ gtmId: "GTM-WRMW3FC" });
	}, []);

	useEffect(() => {
		ptenginLogin();
	}, []);

	useEffect(() => {
		isSupport().then((support) => {
			supportWebP.setSupportWebP(support);
		});
	}, []);

	useEffect(() => {
		if (height <= 0) return;
		if (rfState?.model) return;
		if (typeof window === "undefined") return;
		document.body.style.height = `${height}px`;
	}, [height]);

	useEffect(() => {
		document.documentElement.setAttribute("data-locale", router.locale || "");
	}, []);

	// Per-Page Layouts
	const getLayout = Component.getLayout ?? ((page) => page);

	return getLayout(
		<ErrorBoundary>
			<SWRConfig
				value={{
					errorRetryCount: 3,
				}}
			>
				<Layout>
					<Head>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover, user-scalable=no"
						/>
					</Head>
					<Component {...pageProps} />
				</Layout>
			</SWRConfig>
		</ErrorBoundary>,
	);
}

// @ts-ignore
export default appWithTranslation(MyApp);
