import log2Sentry from "@/utils/log2Sentry";
import React from "react";

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error: React.ErrorInfo) {
    log2Sentry(error);
    return { errorMessage: error.toString() };
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div>
          <h1>Error!</h1>
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;