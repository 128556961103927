import { DepositQuestionsAuditStatus, OCRStatus, OpenAccountRejectInitialReviewReasonTypes } from "@/interfaces/enums";
import { AccountStatus, Answer, MasterAccountInfo, Question, Taxpayer } from "@/interfaces/surveyTypes";
import { isAndroid, isLowIosV16 } from "./parserDevice";
import rfStore from "./rfStore";

interface SelectTab {
	type: string;
	value: string;
}
export const convertCoverName2Abbr = (name: string) => {
	if (name.startsWith("Roctech")) {
		return name.replace("Roctech", "RT");
	}

	if (name.startsWith("V&C")) {
		return name.replace("V&C", "VC");
	}

	if (name.startsWith("2121")) {
		return name.replace("2121", "21");
	}

	if (name.startsWith("Lifestyle")) {
		return name.replace("Lifestyle", "LT");
	}
	return name;
};

export const convertAvatarName2Abbr = (name: string) => {
	if (name.startsWith("Take Five")) {
		return name.replace("Take Five", "TF");
	}

	if (name.startsWith("Techno Punk")) {
		return name.replace("Techno Punk", "TP");
	}

	if (name.startsWith("La Carnivale")) {
		return name.replace("La Carnivale", "LC");
	}

	if (name.startsWith("Disco Flow")) {
		return name.replace("Disco Flow", "DF");
	}

	if (name.startsWith("RockStar")) {
		return name.replace("RockStar", "RS");
	}

	if (name.startsWith("RockStar")) {
		return name.replace("RockStar", "RS");
	}
	return name;
};

export const getNFTRarityStarByRank = (rank: number) => {
	if (rank === 0) {
		return 5;
	}

	if (rank > 0 && rank <= 500) {
		return 4;
	}

	if (rank > 500 && rank <= 1500) {
		return 3;
	}

	if (rank > 1500 && rank <= 3500) {
		return 2;
	}

	return 1;
};

// 开户问题所在页码映射
const PageAccountOpeningQuestionLocated = {
	nationality: 1,
	documentType: 2,
	firstName: 5,
	// middleName: 5, // 非必填，忽略check
	lastName: 5,
	gender: 6,
	birthday: 7,
	documentNumber: 8,
	countryOfResidence: 9,
	address: 9,
};
type AccoountOpeningKeys = keyof typeof PageAccountOpeningQuestionLocated;
/**
 * 1. 证件驳回or没有证件，第一页
 * 2. 问题驳回，第四页
 * 3. 其它情况，跳到没有answer的页面
 */
export const getAccountOpeningSurveyPageNumber = ({
	questions,
	answers,
	masterAccount,
}: {
	questions: Question[];
	answers: Answer[];
	masterAccount: MasterAccountInfo;
}) => {
	// 问题驳回 || 没有第一题
	if (
		masterAccount.rejectReason === OpenAccountRejectInitialReviewReasonTypes.REASON_KEY_7 ||
		!answers.find((el) => el.field == "nationality")
	) {
		return 1;
	}

	// 未提交ocr
	if (masterAccount.ocrStatus === OCRStatus.UN_COMMIT) {
		return 2;
	}

	for (const key in PageAccountOpeningQuestionLocated) {
		const answer = answers.find((el) => el.field == key);
		if (!answer) {
			return PageAccountOpeningQuestionLocated[key as AccoountOpeningKeys];
		}
	}

	return 11;
};

// TODO: 非必填摘出去
const PageDepositQuestionLocated = {
	employment: 4,
	employer: 5,
	occupation: 5,
	isFamiliesSinorExcutive: 5,
	// employerOfFamilies: 5, // 非必填，忽略check
	isEmployedByOtherBrokerage: 5,
	// anotherEmployer: 5, // 非必填，忽略check
	investExperience: 6,
	investObjectives: 7,
	netWorth: 8,
	liquidNetWorth: 8,
	anualNetIncome: 8,
};
type DepositKeys = keyof typeof PageDepositQuestionLocated;
/**
 * 1. 邮箱或者手机未绑定， 第一页
 * 3. 问题驳回， 第三页
 * 3. 无纳税人识别号， 第三页
 * 5. 其它情况，跳到没有answer页面
 */
export const getDepositSurveyPageNumber = ({
	answers,
	accountStatus,
	taxResidences,
	masterAccount,
	employment,
}: {
	answers: Answer[];
	accountStatus: AccountStatus;
	taxResidences: Taxpayer[] | undefined;
	masterAccount: MasterAccountInfo | undefined;
	employment?: string;
}) => {
	if (!accountStatus.hasEmail || !accountStatus.hasPhone) {
		return 1;
	}

	const auditRejected = masterAccount?.depositQuestionsAuditStatus === DepositQuestionsAuditStatus.REJECT;
	const noTaxResidences = taxResidences?.length === 0;

	if (noTaxResidences || auditRejected) {
		return 3;
	}

	for (const key in PageDepositQuestionLocated) {
		const answer = answers.find((el) => el.field == key);
		if (!answer) {
			const pageNumber = PageDepositQuestionLocated[key as DepositKeys];
			// 当职业选的不是员工，跳过职业信息详情页
			if (pageNumber === 5 && employment !== "0") {
				continue;
			}
			return pageNumber;
		}
	}

	return 3;
};

export function checkSafeArea() {
	if (!isInApp()) {
		return;
	}

	const body = document.body;

	// const div = document.createElement("div");
	// div.style.cssText = `padding-top: env(safe-area-inset-top); padding-top: constant(safe-area-inset-top);`;

	// body.append(div);
	// if (!div.offsetHeight) {
	//   body.classList.add("app-android-safe-area");
	// }

	// div.remove();
	if (isAndroid()) {
		body.classList.add("app-android-safe-area");
	}
}

export function isInApp() {
	return !!rfStore.getRFState()?.model;
}

// 黑白主题 不随客户端决定
const ThemeLightWhitelist = [
	"/activities/deposit-rewards",
	"/activities/invite-deposit",
	"/copilot",
	"/activities/annual-report",
	"/statement",
];

const ThemeDarkWhitelist = [
	"/survey/crypto",
	"/trade-gpt/intent",
	"/activities/zero-commission",
	"/activities/holiday2022/invited",
];
const isAlwaysLight = (asPath: string) => ThemeLightWhitelist.some((item) => asPath.includes(item));
const isAlwaysDark = (asPath: string) => ThemeDarkWhitelist.some((item) => asPath.includes(item));

export const isAlwaysTheme = (asPath: string) => {
	if (isAlwaysDark(asPath)) {
		return "dark";
	}
	if (isAlwaysLight(asPath)) {
		return "light";
	}
	return "";
};

export const settingThemeStyle = (theme: string) => {
	if (typeof document === "undefined" || typeof window === "undefined") return;

	document.documentElement.setAttribute("data-theme", theme);
	const bgVar = theme === "dark" ? "var(--palette-black)" : "var(--palette-white)";

	document.documentElement.style.setProperty("--bg-theme-color", bgVar);
};

/**
 * 帮助中心 host + locale
 * 只有中英文
 * 没有支持的就返回 英文
 * @param { String } locale
 * @return {String} https://help.rockflow.tech/zh-Hans || https://help.rockflow.tech/en
 *
 */
export function helpCenterLocaleHost(locale?: string) {
	const helpCenterSupportLocales = ["en", "zh-hans", "es", "pt", "zh-hant"]; // 未来帮助中心添加语言 添加这里

	const suffix = helpCenterSupportLocales.includes(String(locale).toLocaleLowerCase()) ? `/${locale}` : "/en";

	return `${process.env.NEXT_PUBLIC_APP_HELP_CENTER}${suffix}`;
}

// 判断app的版本
// 将版本号转换为数字类型
function versionToNumber(version: string) {
	const parts = version.split(".");
	let number = 0;
	for (let i = 0; i < parts.length; i++) {
		number += parseInt(parts[i]) * Math.pow(100, 2 - i);
	}
	return number;
}

// 比较两个版本号的大小关系
export function compareVersion(v1: string, v2: string) {
	const num1 = versionToNumber(v1);
	const num2 = versionToNumber(v2);
	if (num1 < num2) {
		return -1;
	} else if (num1 > num2) {
		return 1;
	} else {
		return 0;
	}
}

// 涨跌色
export const getUpDownColorGreen = () => {
	const rfState = rfStore.getRFState();
	if (!rfState) {
		return true;
	}
	const upDownColor = rfState.upDownColor;

	if (!upDownColor) return true;

	return Number(upDownColor) === 1;
};

export const getUpDownColor = (value?: number | string) => {
	const zero = "text-black dark:text-white";
	const green = "theme-text_green";
	const red = "theme-text_red";

	const upIsGreen = getUpDownColorGreen();
	if (Number(value) === 0) {
		return zero;
	}

	if (Number(value) > 0) {
		return upIsGreen ? green : red;
	}

	return upIsGreen ? red : green;
};

export function isLowIosV15() {
	const rfState = rfStore.getRFState();
	if (!rfState?.model) return false;

	const isAndroid = rfState?.model === "android";
	if (isAndroid) return false;

	if (!isLowIosV16()) return false;

	const appVersion = rfState?.appVersion || "";

	// ios webview rightBtn
	const limitVersion = "4.9.7";

	return compareVersion(appVersion, limitVersion) < 0;
}
