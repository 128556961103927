import { settingThemeStyle } from "./util";

export default function settingTheme() {
	if (typeof document === "undefined" || typeof window === "undefined") {
		return "light";
	}
	const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
	console.log(mediaQueryList);
	const applyTheme = (event: MediaQueryListEvent) => {
		const theme = event.matches ? "dark" : "light";
		console.log(event.matches);
		settingThemeStyle(theme);
	};

	// 监听系统主题变化
	mediaQueryList.addEventListener("change", applyTheme);

	return mediaQueryList.matches ? "dark" : "light";
}
