interface SupportWebPProps {
  supportWebP: boolean;
  setSupportWebP: (status: boolean) => void;
  getSupportWebP: () => boolean;
}

export const isSupport = (): Promise<boolean> => {
  return new Promise((resolve, _) => {
    try {
      const img = new Image();
      img.onload = function () {
        var result = img.width > 0 && img.height > 0;
        resolve(result);
      };
      img.onerror = function () {
        resolve(false);
      };
      img.src =
        "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
    } catch (error) {
      console.log(error); // 没有必要处理错误，错误就不支持webP
    }
  });
};

class SupportWebP implements SupportWebPProps {
  supportWebP = false;
  setSupportWebP(status: boolean) {
    this.supportWebP = status;
  }
  getSupportWebP() {
    return this.supportWebP;
  }
}

const supportWebP = new SupportWebP();

export default supportWebP;
