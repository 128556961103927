import { UAParser } from "ua-parser-js";

const deviceName = new UAParser().getResult().os.name;

const browser = new UAParser().getResult().browser;

export const isIos = () => deviceName?.toLocaleLowerCase() === "ios";

export const isMac = () => deviceName?.toLocaleLowerCase() === "mac os";

export const isAndroid = () => deviceName === "android";

export const isWindows = () => deviceName === "windows";

export const isWeixin = () => browser.name === "WeChat";

export const isPC = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  // 检查设备类型，如果类型为 undefined 或 'desktop'，则是 PC 环境
  return !result.device.type || result.device.type === "desktop";
};

export const browserVersion = () => {
  return Number(browser.version);
};

// iso 低版本
export const isLowIos = () => {
  return isIos() && browserVersion() < 13.6;
};

export const isLowIosV16 = () => {
  const version = new UAParser().getResult().os.version;

  return isIos() && Number(version) < 16.0;
};
