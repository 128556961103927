import { nanoid } from "nanoid";
import rfStore from "./rfStore";

export default function log2Ptengin(eventName: string, property: object = {}) {
  window.ptengine &&
    window.ptengine.track(eventName, { property }, { transport: "beacon" });
}


export function ptenginLogin () {
  if (typeof window === 'undefined') return;
  if (!window.ptengine) return;
  const  rfState = rfStore.getRFState();
  const uid = rfState?.userId
  if (!uid) return;
  
  window.ptengine && window.ptengine.identify(uid, {});
}