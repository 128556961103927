import * as Sentry from "@sentry/nextjs";
import rfStore from "./rfStore";
/**
 * 手动上报日志到sentry
 *
 * @params {Error | any} error 错误信息
 * @params {string} level 等级，根据不同等级 决定是否发出警告等
 *
 */
export default function log2Sentry(
  error: Error | unknown,
  level?: Sentry.Severity | Sentry.SeverityLevel,
  extra?: any
): void {
  const state = rfStore.getRFState();

  Sentry.captureException(error, {
    level: level || "error",
    user: {
      region: state?.region,
      userId: state?.userId,
    },
    extra: extra,
  });
}
