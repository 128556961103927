import { nanoid } from "nanoid";

const TOKEN_KEY = "rf-token";
const USER_ID_KEY = "rf-user-id";
const REGION_KEY = "rf-region";
const DEPOSIT_KEY = "rf-deposit";
const ROCK_BOT_KEY = "rf-rock-bot";

const setToken = (token: string) => {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(TOKEN_KEY, token);
};
const getToken = () => {
  if (typeof window === "undefined") {
    return "";
  }

  return localStorage.getItem(TOKEN_KEY) || "";
};

const setUserId = (userId: string) => {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(USER_ID_KEY, userId);
};

const getUserId = () => {
  if (typeof window === "undefined") {
    return "";
  }

  return localStorage.getItem(USER_ID_KEY) || "";
};

const setRegion = (region: string) => {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(REGION_KEY, region);
};

const getRegion = () => {
  if (typeof window === "undefined") {
    return "";
  }

  return localStorage.getItem(REGION_KEY) || "";
};

// deposit
const setDeposit = (currency: string, price: string) => {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(
    DEPOSIT_KEY,
    JSON.stringify({
      currency,
      price,
    })
  );
};

const getDeposit = () => {
  if (typeof window === "undefined") {
    return null;
  }

  try {
    const dataStr = localStorage.getItem(DEPOSIT_KEY);
    if (!dataStr) {
      throw Error("missing localStore data");
    }
    return JSON.parse(dataStr);
  } catch (e) {
    return null;
  }
};

const setRockBotUUID = () => {
  if (typeof window === "undefined") {
    return;
  }
  if (localStorage.getItem(ROCK_BOT_KEY)) {
    return;
  }
  localStorage.setItem(ROCK_BOT_KEY, nanoid());
};

const getRockBotUUID = () => {
  if (typeof window === "undefined") {
    return "";
  }

  return localStorage.getItem(ROCK_BOT_KEY);
};

const logout = () => {
  setToken("");
  setUserId("");
  setRegion("");
};

const rfLocalStore = {
  setToken,
  getToken,
  setUserId,
  getUserId,
  setRegion,
  getRegion,
  setDeposit,
  getDeposit,
  setRockBotUUID,
  getRockBotUUID,
  logout,
};

export default rfLocalStore;
