/**
 * 多语言参数
 * zh-Hans => zh-cn
 * zh-Hant => zh-tw
 */
export function transferLanguage(locale: string) {
  const _locale = String(locale).toLocaleLowerCase();
  if (_locale === "zh-hans") return "zh-cn";
  if (_locale === "zh-hant") return "zh-tw";
  return locale;
}
