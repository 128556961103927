import { GlobalState } from "@/interfaces/common";

const InitialState: GlobalState = {
	appId: "1",
	appVersion: "1.0.0",
	deviceId: "h5-node-server",
	osVersion: "linux",
	platformType: "3",
	region: "CN",
};

/**
 * 仅用于初始化全局数据
 */
class RFStore {
	private static instance: RFStore;

	state: GlobalState | undefined;

	constructor() {
		if (!RFStore.instance) {
			this.state = InitialState;
			RFStore.instance = this;
		}
		return RFStore.instance;
	}

	setRFState(newState: GlobalState) {
		this.state = newState;
	}

	getRFState() {
		return this.state;
	}
}

const store = new RFStore();
export default store;
