import React from "react";
import Script from "next/script";
import rfStore from "@/utils/rfStore";
import log2Sentry from "@/utils/log2Sentry";
import { ptenginLogin } from "@/utils/logo2Ptengin";
interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Script
        src="/third-party/sensorsdata.min.js"
        onLoad={() => {
          const sensors = window.sensorsDataAnalytic201505;
          sensors.init({
            server_url: process.env.NEXT_PUBLIC_SENSORS_SERVER_URL,
            heatmap: { scroll_notice_map: "not_collect" },
            is_track_single_page: true,
            use_client_time: true,
            send_type: "beacon",
            // show_log: process.env.NODE_ENV === "development" ? true : false,
            show_log: false,
            app_js_bridge: rfStore.getRFState()?.model ? true : false, // APP H5 打通
          });

          sensors.quick("autoTrack"); //用于采集 $pageview 事件。
          sensors.use("PageLeave");
          sensors.use("PageLoad");
        }}
      ></Script>

      <Script
        src={process.env.NEXT_PUBLIC_PT_ENGINE_SDK}
        onError={(e) => {
          log2Sentry("ptengine loaded error", "error", e);
        }}
        onLoad={() => {
          ptenginLogin()
        }}
      ></Script>
      {children}
    </>
  );
}
